// import { request } from 'express'
import axios from 'axios'
import requests from '../requests'
import { fetchUserManagement } from './utils'
import { setStorage } from '~/utils/storage'

export default function ({ store, route, next, $auth }) {
  if ($auth.loggedIn) {
    let isUsingSSO = ''
    axios
      .get(`${process.env.HUB_SERVICE_URL}/api/core/v1/client_configs/config`)
      .then(({ data: response }) => {
        isUsingSSO =
          process.env.NODE_ENV === 'development'
            ? false
            : response.data?.sso?.is_using_parameter ||
              response.data?.sso?.status
        if (isUsingSSO) {
          const cookieObj = new URLSearchParams(
            document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')
          )
          const validToken = cookieObj.get('qontak._token.hub')
          const validTokenRefresh = cookieObj.get('qontak._refresh_token.hub')

          const refreshTokenPayload = {
            refresh_token: validTokenRefresh,
            grant_type: 'refresh_token',
          }

          if (validTokenRefresh) {
            // Check token and map token when pages load first
            if (
              !localStorage.getItem('time_to_refresh') ||
              new Date().getTime() >= localStorage.getItem('time_to_refresh')
            ) {
              const refreshTokenEndpoint =
                `${process.env.HUB_SERVICE_URL}/sso_oauth/token`.replace(
                  /([^:]\/)\/+/g,
                  '$1'
                )

              axios
                .post(refreshTokenEndpoint, refreshTokenPayload, {
                  authorization: validToken,
                })
                .then((resp) => {
                  const token = 'bearer ' + resp.data.data.access_token
                  const mapRefreshToken = resp.data.data.refresh_token
                  const freshToken = resp.data.data.access_token
                  $auth.setToken('hub', token)
                  $auth.setRefreshToken('hub', mapRefreshToken)
                  setStorage('qontak._token.hub.share', token, {
                    domain: process.env.HOST_URL,
                    maxAge: 3600,
                  })
                  store.commit(
                    'preferences/SET_SSO_REFRESH_TIME',
                    (resp.data.data.created_at +
                      (resp.data.data.expires_in - 600)) *
                      1000
                  ) // in seconds
                  store.commit('preferences/SET_NEW_TOKEN', freshToken)
                })
                .catch(() => {})
            }
          } else {
            $auth.logout()
            window.location.replace(
              `${process.env.SSO_ACCOUNT_URL}/sign_out?client_id=${process.env.SSO_CLIENT_ID}`
            )
          }
        }
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.error('middleware: ', error)
      })
    if (!store.getters['preferences/isInitiated']) {
      requests.settings
        .feature(
          {
            token: $auth.getToken('hub') || '',
            actor: 'user',
            access: 1,
            target: 'system',
            role: $auth.user.role || '',
          },
          $auth.getToken('hub')
        )
        .subscribe(
          async (response) => {
            const resBillingInfo = await fetchBillingInfo()
            store
              .dispatch('organization/getOrganizationSettings', {
                list: response.data,
                route: route.name,
                billingInfo: resBillingInfo,
              })
              .then(() => {
                const privilege = store.getters['preferences/getFeatures']
                // await store.commit('preferences/FEATURE', response)
                const menuLists = []
                const submenuLists = []
                const submenutabLists = []

                Object.keys(privilege.menu).forEach((menu) => {
                  if (privilege.menu[menu].status) menuLists.push(menu)
                })

                Object.keys(privilege.submenu).forEach((submenu) => {
                  if (privilege.submenu[submenu].status)
                    submenuLists.push(submenu)
                })

                Object.keys(privilege.submenutab).forEach((submenutab) => {
                  if (privilege.submenutab[submenutab].status)
                    submenutabLists.push(submenutab)
                })

                const routePath = {
                  menu: route.path.split('/')[1],
                  submenu: route.path.split('/')[2],
                  submenutab: route.path.split('/')[3],
                }

                if (!$auth.loggedIn) {
                  const additionalMenuPage = ['reset_password']
                  additionalMenuPage.forEach((page) => {
                    menuLists.push(page)
                  })
                }

                const additionalMenu = [
                  'outlookcallback',
                  'shopeecallback',
                  'sso-callback',
                  'contacts-upload-multiple',
                ]
                additionalMenu.forEach((page) => {
                  menuLists.push(page)
                })

                const additionalSubmenuList = ['scorecards']

                additionalSubmenuList.forEach((page) => {
                  submenuLists.push(page)
                })

                /**
                 * Page name that can be accessible directly from url
                 * welcome: ['integrations/qontak_crm/welcome']
                 * tokopedia: ['integrations/ecommerce/tokopedia']
                 * shopee: ['integrations/ecommerce/shopee']
                 * lazada: ['integrations/ecommerce/lazada']
                 * signup: ['integrations/whatsapp/signup']
                 */
                const additionalSubmenu = [
                  'create',
                  'hsm',
                  'select',
                  'welcome',
                  'summary',
                  'activity',
                  'omnichannel',
                  'chatbot',
                  'tokopedia',
                  'shopee',
                  'lazada',
                  'signup',
                ]
                additionalSubmenu.forEach((page) => {
                  submenutabLists.push(page)
                })

                // rename submenu: 'import_export' with underscore to be dash
                submenuLists.forEach((menu, index) => {
                  if (menu === 'import_export')
                    submenuLists[index] = 'import-export'
                })

                // #a. Check if menu page is not 404, 500 or unprivileged
                if (
                  routePath.menu !== '404' &&
                  routePath.menu !== '500' &&
                  routePath.menu !== 'unprivileged'
                ) {
                  //  #b. Check if menu list > 0 and includes current menu
                  if (
                    (menuLists.length && menuLists.includes(routePath.menu)) ||
                    routePath.menu === ''
                  ) {
                    // #c. Check if there is submenu next
                    if (routePath.submenu) {
                      // #d. Check if submenu list > 0 and includes current submenu
                      if (
                        (submenuLists.length &&
                          submenuLists.includes(routePath.submenu)) ||
                        Object.keys(route.params).length > 0
                      ) {
                        // #e. Check if there is submenutab next
                        if (routePath.submenutab) {
                          // #f. Check if submenutab list > 0 and includes current submenutab
                          if (
                            (submenutabLists.length &&
                              submenutabLists.includes(routePath.submenutab)) ||
                            Object.keys(route.params).length > 0
                          ) {
                            // #g. Do now show page while redirect process is running, then we add 1 second for it
                            setTimeout(() => {
                              store.commit(
                                'preferences/UPDATE_FIRST_INIT',
                                true
                              )
                            }, 1000)
                          } else {
                            // #g. Do now show page while redirect process is running, then we add 1 second for it
                            setTimeout(() => {
                              store.commit(
                                'preferences/UPDATE_FIRST_INIT',
                                true
                              )
                            }, 1000)
                            // #h. Redirect to 404 page (page not exist page)
                            return next(`/404`)
                          }
                        } else {
                          // #g. Do now show page while redirect process is running, then we add 1 second for it
                          store.commit('preferences/UPDATE_FIRST_INIT', true)
                        }
                      } else {
                        // #g. Do now show page while redirect process is running, then we add 1 second for it
                        setTimeout(() => {
                          store.commit('preferences/UPDATE_FIRST_INIT', true)
                        }, 1000)
                        // #h. Redirect to 404 page (page not exist page)
                        return next(`/404`)
                      }
                    } else {
                      // #g. Do now show page while redirect process is running, then we add 1 second for it
                      setTimeout(() => {
                        store.commit('preferences/UPDATE_FIRST_INIT', true)
                      }, 1000)
                    }
                  } else {
                    // #g. Do now show page while redirect process is running, then we add 1 second for it
                    setTimeout(() => {
                      store.commit('preferences/UPDATE_FIRST_INIT', true)
                    }, 1000)
                    // #h. Redirect to 404 page (page not exist page)
                    return next(`/404`)
                  }
                } else {
                  // #g. Do now show page while redirect process is running, then we add 1 second for it
                  setTimeout(() => {
                    store.commit('preferences/UPDATE_FIRST_INIT', true)
                  }, 1000)
                }

                fetchUserManagement($auth, store, false)

                // // When user redirected after login
                // if (route.name.split('-')[0] === 'login') {
                //   if ($auth.loggedIn) {
                //     return next('/' + privilege.homeUrl.url)
                //   }
                // }

                // // When user visit home url
                // if (route.name === 'index' && route.path === '/') {
                //   if ($auth.loggedIn) {
                //     return next('/' + privilege.homeUrl.url)
                //   } else {
                //     return next('/login')
                //   }
                // }

                // if (route.name.split('-').length <= 1) {
                //   if (!menuLists.includes(route.name.split('-')[0])) {
                //     return next('/' + privilege.homeUrl.url)
                //   }
                // } else if (route.name.split('-').length === 2) {
                //   if (!submenuLists.includes(route.name.split('-')[1])) {
                //     return next('/' + privilege.homeUrl.url)
                //   }
                // } else if (route.name.split('-').length >= 3) {
                //   if (
                //     !submenutabLists.includes(route.name.split('-')[2]) &&
                //     !route.name.includes('hsm') &&
                //     route.name.split('-')[2] !== 'id' &&
                //     route.name.split('-')[2] !== 'create'
                //   ) {
                //     return next('/' + privilege.homeUrl.url)
                //   }
                // }
              })
          },
          (errors) => {},
          () => {}
        )
    }
  }

  function fetchBillingInfo() {
    return store
      .dispatch('preferences/getBillingInfoConfig')
      .then((res) => {
        return res
      })
      .catch((error) => {
        return error
      })
  }
}
